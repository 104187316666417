import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './footer.component.css';
import sh from '../../../../assets/images/icons/shield.png';
import candado from '../../../../assets/images/icons/cand.png';
import logo from '../../../../assets/images/initial-logo.png';

const FooterComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="footer" id="footer">
      <div className="container w-100">
        <div className="row w-100">
          <div className="col-sm-4 d-flex flex-column align-items-center justify-content-center">
            <img src={sh} alt="legal-warning" />
            <Link to="/legal-warning">{t('footer.legal.warning')}</Link>
          </div>
          <div className="col-sm-4 d-flex flex-column align-items-center justify-content-center">
            <img src={candado} alt="cookies-policy" />
            <Link to="/cookies-policy">{t('footer.cookies.policy')}</Link>
          </div>
          <div className="col-sm-4 d-flex flex-column align-items-center justify-content-center">
            <img src={logo} alt="privacy-policy" />
            <Link to="/privacy-policy">{t('footer.privacy.policy')}</Link>
          </div>
          <div className="col-12 d-flex align-items-center justify-content-center">
            &copy;{t('footer.rights')} Venecia 2023
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
