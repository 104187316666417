import React from 'react';
import { useTranslation } from 'react-i18next';

import { SectionTitle, VideoButton } from '../../components';
import './about-us.component.css';
// import aboutUs from '../../../../assets/images/about-us.png';
import aboutUs from '../../../../assets/videos/about-us.mp4';

const AboutUsComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="about-us" id="about-us">
      <div className="container">
        <div className="row align-items-start justify-content-center">
          <div className="col-12">
            <SectionTitle title={t('about-us.title')} />
          </div>
          <div className="col-lg-6">
            <p className="subtitle">
              {t('about-us.subtitle')}
            </p>
            <p className="subtitle">
              {t('about-us.subtitle3')}
            </p>
          </div>
          <div className="col-lg-6 d-flex align-items-end justify-content-end">
            {/* <ImageButton backgroundImage={aboutUs} title={t('about-us.button')} height="512px" href="#contact" /> */}
            <VideoButton video={aboutUs} type="video/mp4" title={t('about-us.button')} href="#contact" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsComponent;
