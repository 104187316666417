import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPiggyBank, faUserTie, faFingerprint, faFire, faIndustry, faMagnifyingGlass, faCode, faBinoculars, faArrowUp, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '../../components';
import './services.component.css';

const ServicesComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="services" id="services">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title={t('services.title')} />
          </div>
          <div className="col-md-4">
            <div className="service-name d-flex align-items-center justify-content-center">
              IT Headhunter
            </div>
            <div className="d-flex flex-wrap align-items-stretch justify-content-stretch">
              <div className="service-detail d-flex align-items-center justify-content-start"><FontAwesomeIcon icon={faPiggyBank} size="3x" />{t('services.ithh.1')}</div>
              <div className="service-detail d-flex align-items-center justify-content-start"><FontAwesomeIcon icon={faUserTie} size="3x" />{t('services.ithh.2')}</div>
              <div className="service-detail d-flex align-items-center justify-content-start"><FontAwesomeIcon icon={faFingerprint} size="3x" />{t('services.ithh.3')}</div>
              <div className="service-detail d-flex align-items-center justify-content-start"><FontAwesomeIcon icon={faFire} size="3x" />{t('services.ithh.4')}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="service-name d-flex align-items-center justify-content-center">
              Software Factory
            </div>
            <div className="d-flex flex-wrap align-items-stretch justify-content-stretch">
              <div className="service-detail d-flex align-items-center justify-content-start"><FontAwesomeIcon icon={faIndustry} size="3x" />{t('services.swf.1')}</div>
              <div className="service-detail d-flex align-items-center justify-content-start"><FontAwesomeIcon icon={faMagnifyingGlass} size="3x" />{t('services.swf.2')}</div>
              <div className="service-detail d-flex align-items-center justify-content-start"><FontAwesomeIcon icon={faCode} size="3x" />{t('services.swf.3')}</div>
              <div className="service-detail d-flex align-items-center justify-content-start"><FontAwesomeIcon icon={faBinoculars} size="3x" />{t('services.swf.4')}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="service-name d-flex align-items-center justify-content-center">
              BPO
            </div>
            <div className="d-flex flex-wrap align-items-stretch justify-content-stretch">
              <div className="service-detail d-flex align-items-center justify-content-start"><FontAwesomeIcon icon={faArrowUp} size="3x" />{t('services.bpo.1')}</div>
              <div className="service-detail d-flex align-items-center justify-content-start"><FontAwesomeIcon icon={faBuilding} size="3x" />{t('services.bpo.2')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServicesComponent;
