import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

import './navbar.component.css';
import logo from '../../../../assets/images/logo.png';
import es from '../../../../assets/images/flags/es.png';
import uk from '../../../../assets/images/flags/uk.png';

const NavbarComponent = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="navContainer">
      <Navbar collapseOnSelect expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="#home"><img className="logo" src={logo} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#about-us">{t('about-us.menu')}</Nav.Link>
              <Nav.Link href="#ecosystems">{t('ecosystems.menu')}</Nav.Link>
              <Nav.Link href="#services">{t('services.menu')}</Nav.Link>
              <Nav.Link href="#sectors">{t('sectors.menu')}</Nav.Link>
              <Nav.Link href="#contact">{t('contact.menu')}</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link href="https://www.linkedin.com/company/venecia-it-headhunter/" target="_blank">
                <FontAwesomeIcon className="icon" icon={faLinkedin} />
              </Nav.Link>
              <Nav.Link href="https://wa.me/+34635748362" target="_blank">
                <FontAwesomeIcon className="icon" icon={faWhatsapp} />
              </Nav.Link>
              <Nav.Link>
                <span
                  className="btn-flag"
                  onClick={() => i18n.changeLanguage('es')}
                  style={{ display: i18n.language === 'en' ? 'block' : 'none' }}
                >
                  <img src={es} alt="Spanish" />
                </span>
                <span
                  className="btn-flag"
                  onClick={() => i18n.changeLanguage('en')}
                  style={{ display: i18n.language === 'es' ? 'block' : 'none' }}
                >
                  <img src={uk} alt="English" />
                </span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
