import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

import { SectionTitle } from '../../components';
import './contact.component.css';
import world from '../../../../assets/images/world.png';

const ContactComponent = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [anyError, setAnyError] = useState(false);
  const form = useRef();

  const { t, i18n } = useTranslation();

  const sendEmail = (e) => {
    e.preventDefault();
    const templateName = `info-vithhe-${i18n.language}`;

    setIsFormSubmitted(true);
    emailjs
      .sendForm('info-veneciaitheadhunter', templateName, form.current, 'ub8GKrpW2QEv8Jm4j')
      .then((result) => {
        // eslint-disable-next-line
        console.log(result);
        setAnyError(false);
      }, (error) => {
        // eslint-disable-next-line
        console.log(error);
        setAnyError(true);
      });
  };

  return (
    <div className="contact" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-12 d-flex align-items-start justify-content-start">
            <SectionTitle title={t('contact.title')} />
          </div>
          <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
            <img className="world-image" src={world} alt="world" />
            <p>{t('contact.paragraph')}</p>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <div className="card-contact">
              <div className="row">
                <div className="col-12">
                  {!isFormSubmitted ? (
                    <form ref={form} onSubmit={sendEmail}>
                      <div className="row">
                        <div className="col-12">
                          <input className="form-control-lg w-100 my-1" type="text" placeholder={t('contact.form.name')} name="user_name" required />
                        </div>
                        <div className="col-12">
                          <input className="form-control-lg w-100 my-1" type="email" placeholder={t('contact.form.email')} name="user_email" required />
                        </div>
                        <div className="col-12">
                          <textarea
                            className="form-control-lg w-100 my-1"
                            placeholder={t('contact.form.message')}
                            name="message"
                            required
                          />
                        </div>
                        <div className="col-12">
                          <div className="form-check">
                            <input type="radio" className="form-check-input" name="type-contact" value="client" id="client" />
                            <label htmlFor="client" className="form-check-label">{t('contact.form.client')}</label>
                          </div>
                          <div className="form-check">
                            <input type="radio" className="form-check-input" name="type-contact" value="candidate" id="candidate" checked />
                            <label htmlFor="candidate" className="form-check-label">{t('contact.form.candidate')}</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="checked" id="privacy-policy" required />
                            <label className="form-check-label" htmlFor="privacy-policy">
                              {t('contact.form.compliance')} <Link to="privacy-policy">{t('footer.privacy.policy')}</Link>
                            </label>
                          </div>
                        </div>
                        <div className="col-6 d-flex flex-column align-items-center justify-content-center pb-2">
                          <input type="submit" className="btn btn-primary" value={t('contact.form.send')} id="submit" />
                        </div>
                        <div className="col-6 d-flex flex-column align-items-center justify-content-center pb-2">
                          <input type="reset" className="btn btn-secondary" value={t('contact.form.reset')} />
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div>
                      <h3 className="head-text">
                        {
                          anyError
                            ? t('contact.form.error')
                            : t('contact.form.thanks')
                        }
                      </h3>
                    </div>
                  )}
                </div>
                <div className="col-12 mt-5">
                  <p><FontAwesomeIcon icon={faHome} />Calle Princesa, 31. Planta 2, Puerta 2. 28008. Madrid. Madrid. España.</p>
                </div>
                <div className="col-12">
                  <p><FontAwesomeIcon icon={faEnvelope} />&nbsp;
                    <a href="mailto:info@veneciait.com">info@veneciait.com</a>
                  </p>
                </div>
                <div className="col-12">
                  <p><FontAwesomeIcon icon={faPhone} /> +34 635 748 362</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactComponent;
