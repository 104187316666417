import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {
  CookiesPolicyComponent,
  LegalWarningComponent,
  PrivacyPolicyComponent,
  LayoutComponent,
  NotFoundComponent,
  HomeComponent,
} from './ui/pages';

const AppComponent = () => (
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutComponent />}>
          <Route index element={<HomeComponent />} />
          <Route path="cookies-policy" element={<CookiesPolicyComponent />} />
          <Route path="legal-warning" element={<LegalWarningComponent />} />
          <Route path="privacy-policy" element={<PrivacyPolicyComponent />} />
          <Route path="*" element={<NotFoundComponent />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </>
);

export default AppComponent;
