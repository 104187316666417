import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faExclamation } from '@fortawesome/free-solid-svg-icons';

const NotFoundComponent = () => (
  <div className="privacy-policy" id="privacy-policy">
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex flex-column align-items-center justify-content-center">
          <h1>Page Not Found <FontAwesomeIcon icon={faExclamation} /></h1>
          <Button variant="primary" href="/"><FontAwesomeIcon icon={faBackward} />Back</Button>
        </div>
      </div>
    </div>
  </div>
);

export default NotFoundComponent;
