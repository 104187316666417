import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonDigging } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { ImageButton } from '../../components';
import './vacancies.component.css';
import lookingForAJob from '../../../../assets/images/looking_for_a_job.png';
import hiring from '../../../../assets/images/hiring.png';

const VacanciesComponent = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const defaultHandler = () => {
    if (!show) {
      setShow(true);
      setInterval(() => setShow(false), 10000);
    }
  };

  return (
    <div className="vacancies" id="vacancies">
      <div className="container justify-content-center">
        <div className="row">
          <div className="col-lg-6 py-0 d-flex flex-column align-items-start justify-content-center">
            <p className="content">{t('vacancies.title')}</p>
            <p className="content"><strong>{t('vacancies.subtitle')}</strong></p>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6 col-sm-6 d-flex align-items-stretch">
                <ImageButton
                  backgroundImage={lookingForAJob}
                  title={t('vacancies.button.sing-up')}
                  height="256px"
                  onClick={() => defaultHandler()}
                />
              </div>
              <div className="col-lg-6 col-sm-6 d-flex align-items-stretch">
                <ImageButton
                  backgroundImage={hiring}
                  title={t('vacancies.button.list')}
                  height="256px"
                  onClick={() => defaultHandler()}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <Alert show={show} key="success" variant="success">
              <FontAwesomeIcon icon={faPersonDigging} />{t('vacancies.working')}
            </Alert>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacanciesComponent;
