import React from 'react';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

import {
  AboutUsComponent,
  VacanciesComponent,
  EcosystemsComponent,
  ServicesComponent,
  ContactComponent,
  SectorsComponent,
} from '../../sections';

const HomeComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={t('app.cookies.consent.button')}
        cookieName="veneciaitWebsite"
        style={{ background: 'var(--purple)', color: 'var(--white)' }}
        buttonStyle={{ background: 'var(--orange)', color: 'var(--white)', fontSize: '13px' }}
      > {t('app.cookies.consent.text')}&nbsp;
        <Link to="/cookies-policy">{t('footer.cookies.policy')}</Link>
      </CookieConsent>
      <VacanciesComponent />
      <AboutUsComponent />
      <EcosystemsComponent />
      <ServicesComponent />
      <SectorsComponent />
      <ContactComponent />
    </div>
  );
};

export default HomeComponent;
