import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { SectionTitle, Card } from '../../components';
import './ecosystems.component.css';

import image01 from '../../../../assets/images/ecosystem_01.png';
import image02 from '../../../../assets/images/ecosystem_02.png';
import image03 from '../../../../assets/images/ecosystem_03.png';
import image04 from '../../../../assets/images/ecosystem_04.png';
import image05 from '../../../../assets/images/ecosystem_05.png';
import image06 from '../../../../assets/images/ecosystem_06.png';
import image07 from '../../../../assets/images/ecosystem_07.png';
import image08 from '../../../../assets/images/ecosystem_08.png';
import image09 from '../../../../assets/images/ecosystem_09.png';

function EcosystemsComponent() {
  const { t } = useTranslation();

  return (
    <div className="ecosystem" id="ecosystems">
      <div className="container">
        <SectionTitle title={t('ecosystems.title')} />
        <Carousel
          indicators={false}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} size="2x" color="#000000" />}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="2x" color="#000000" />}
        >
          <Carousel.Item>
            <div className="row px-5">
              <div className="col-md-6 col-xl-4 py-2 d-flex align-items-top justify-content-center">
                <Card
                  image={image01}
                  title={t('ecosystems.card.management.title')}
                  description="CIO | CTO | PMO | Head of Infrastructure | It Director | It Manager"
                />
              </div>
              <div className="col-md-6 col-xl-4 py-2 d-flex align-items-top justify-content-center">
                <Card
                  image={image02}
                  title={t('ecosystems.card.development.title')}
                  description="DevOps Engineer | Back-End Developer | Front-End Developer | Full-Stack | Software Architect | Mobile Developer | QA/Tester"
                />
              </div>
              <div className="col-md-6 col-xl-4 py-2 d-flex align-items-top justify-content-center">
                <Card
                  image={image03}
                  title={t('ecosystems.card.ddbb.title')}
                  description="DB2 | Oracle | PostgreSQL | MySql | MariaDB | MongoDB | Redis | Apache Cassandra | Amazon DynamoDB"
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row px-5">
              <div className="col-md-6 col-xl-4 py-2 d-flex align-items-top justify-content-center">
                <Card
                  image={image04}
                  title={t('ecosystems.card.data.title')}
                  description="Digital Analytics | Data Scientis | Data Architect | Data Engineer"
                />
              </div>
              <div className="col-md-6 col-xl-4 py-2 d-flex align-items-top justify-content-center">
                <Card
                  image={image05}
                  title={t('ecosystems.card.erp.title')}
                  description="SAP | ABAP | MS Dynamics | Salesforces"
                />
              </div>
              <div className="col-md-6 col-xl-4 py-2 d-flex align-items-top justify-content-center">
                <Card
                  image={image06}
                  title={t('ecosystems.card.security.title')}
                  description="Cybersecurity | Security Specialist | Security Architects"
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row px-5">
              <div className="col-md-6 col-xl-4 py-2 d-flex align-items-top justify-content-center">
                <Card
                  image={image07}
                  title={t('ecosystems.card.technologies.title')}
                  description="Java | Phyton | .NET | C# | Javascript | PHP | Angular | Swift | Ruby | SQL"
                />
              </div>
              <div className="col-md-6 col-xl-4 py-2 d-flex align-items-top justify-content-center">
                <Card
                  image={image08}
                  title={t('ecosystems.card.system.title')}
                  description="Cloud Architect | Cloud Systems Administrator Network | SysOps| Systems Administrators"
                />
              </div>
              <div className="col-md-6 col-xl-4 py-2 d-flex align-items-top justify-content-center">
                <Card
                  image={image09}
                  title={t('ecosystems.card.sales.title')}
                  description="Account Manager | Sales Manager | Sales Engineer"
                />
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default EcosystemsComponent;
