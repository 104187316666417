import React from 'react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '../../components';
import './sectors.component.css';

import mallorca from '../../../../assets/images/mallorca.png';
import retail from '../../../../assets/images/retail.png';
import telecom from '../../../../assets/images/telecom.png';
import transport from '../../../../assets/images/transport.png';
import health from '../../../../assets/images/health.png';
import bank from '../../../../assets/images/bank.png';
import industrial from '../../../../assets/images/industrial.png';
import government from '../../../../assets/images/government.png';

function SectorsComponent() {
  const { t } = useTranslation();

  return (
    <div className="sectors" id="sectors">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title={t('sectors.title')} />
          </div>
          <div className="col-12 p-0">
            <div className="card-grid">
              <div style={{ background: `url(${mallorca})` }}>
                <span>{t('sectors.sector.1')}</span>
              </div>
              <div style={{ background: `url(${retail})` }}>
                <span>{t('sectors.sector.2')}</span>
              </div>
              <div style={{ background: `url(${telecom})` }}>
                <span>{t('sectors.sector.3')}</span>
              </div>
              <div style={{ background: `url(${transport})` }}>
                <span>{t('sectors.sector.4')}</span>
              </div>
              <div style={{ background: `url(${health})` }}>
                <span>{t('sectors.sector.5')}</span>
              </div>
              <div style={{ background: `url(${bank})` }}>
                <span>{t('sectors.sector.6')}</span>
              </div>
              <div style={{ background: `url(${industrial})` }}>
                <span>{t('sectors.sector.7')}</span>
              </div>
              <div style={{ background: `url(${government})` }}>
                <span>{t('sectors.sector.8')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectorsComponent;
