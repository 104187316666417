import React from 'react';
import { Outlet } from 'react-router-dom';

import {
  HeaderComponent,
  FooterComponent,
} from '../../sections';

const LayoutComponent = () => (
  <div>
    <HeaderComponent />
    <Outlet />
    <FooterComponent />
  </div>
);

export default LayoutComponent;
