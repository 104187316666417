import React from 'react';

import { NavbarComponent } from '../../components';
import './header.component.css';

const HeaderComponent = () => (
  <div className="d-flex align-items-start flex-column header" id="home">
    <NavbarComponent />
    <div className="header-bg">
      &nbsp;
    </div>
  </div>
);

export default HeaderComponent;
