import React from 'react';

import { useTranslation } from 'react-i18next';

import './cookies-policy.component.css';

const CookiesPolicyComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="cookies-policy" id="cookies-policy">
      <div className="container w-100">
        <div className="row w-100">
          <div className="col-12">
            <h1>{t('footer.cookies.policy')}</h1>
          </div>
          <div className="col-12">
            <h3>Acerca de la política de cookies</h3>
            <p>
              Esta página web puede utilizar cookies de terceros que son aquellas que se envían a tu ordenador o terminal desde
              un dominio o una página web que no es gestionada por nosotros, sino por otra entidad que trata los datos obtenidos
              a través de las cookies.
            </p>
            <p>
              En este caso las Cookies son utilizadas con fines estadísticos relacionados con las visitas que recibe y las páginas
              que se consultan, quedando aceptado su uso al navegar por ella.Tu consentimiento aplica a los siguientes dominios:
              veneciait.es, veneciait.com
            </p>
            <h3>¿Que son las Cookies?</h3>
            <p>
              Las cookies son pequeños archivos de texto que se utilizan para almacenar pequeños fragmentos de información. Se
              almacenan en su dispositivo cuando el sitio web se carga en su navegador. Estas cookies nos ayudan a hacer que el
              sitio web funcione correctamente, hacerlo más seguro, brindar una mejor experiencia al usuario, comprender cómo
              funciona el sitio web y analizar qué funciona y dónde necesita mejorar.
            </p>
            <h3>¿Cómo usamos las cookies ?</h3>
            <p>
              Como la mayoría de los servicios en línea, nuestro sitio web utiliza cookies propias y de terceros para varios
              propósitos. Las cookies de origen son principalmente necesarias para que el sitio web funcione correctamente y no
              recopilan ninguno de sus datos de identificación personal.Las cookies de terceros que se utilizan en nuestro sitio
              web son principalmente para comprender cómo funciona el sitio web, cómo interactúa con nuestro sitio web, mantener
              nuestros servicios seguros, proporcionar anuncios que sean relevantes para usted y, en general, brindarle un usuario
              mejor y mejorado. experiencia y ayude a acelerar sus interacciones futuras con nuestro sitio web.
            </p>
            <h3>¿Que tipo de cookies utilizamos?</h3>
            <ul>
              <li>
                Esencial: algunas cookies son esenciales para que pueda experimentar la funcionalidad completa de nuestro sitio.
                Nos permiten mantener las sesiones de los usuarios y prevenir cualquier amenaza a la seguridad. No recopilan ni
                almacenan ninguna información personal. Por ejemplo, estas cookies le permiten iniciar sesión en su cuenta y
                agregar productos a su carrito y realizar el pago de forma segura.
              </li>
              <li>
                Estadísticas: estas cookies almacenan información como el número de visitantes al sitio web, el número de visitantes
                únicos, las páginas del sitio web que se han visitado, la fuente de la visita, etc. Estos datos nos ayudan a
                comprender y analizar el rendimiento del sitio web. y donde necesita mejorar.
              </li>
              <li>
                Marketing: nuestro sitio web muestra anuncios. Estas cookies se utilizan para personalizar los anuncios que
                le mostramos para que sean significativos para usted. Estas cookies también nos ayudan a realizar un seguimiento
                de la eficiencia de estas campañas publicitarias. <br />Los proveedores de publicidad de terceros también pueden
                utilizar la información almacenada en estas cookies para mostrarle anuncios en otros sitios web en el navegador.
              </li>
              <li>
                Funcionales: Son las cookies que ayudan a determinadas funcionalidades no esenciales de nuestro sitio web.
                Estas funcionalidades incluyen incrustar contenido como videos o compartir contenido del sitio web en plataformas
                de redes sociales.
              </li>
              <li>
                Preferencias: estas cookies nos ayudan a almacenar su configuración y preferencias
                de navegación, como las preferencias de idioma, para que tenga una experiencia mejor y más eficiente en futuras
                visitas al sitio web.
              </li>
            </ul>
            <h3>¿Cómo puedo controlar las preferencias de cookies?</h3>
            <p>Si decide cambiar sus preferencias más adelante a través de su sesión de navegación, puede hacer clic en la opción
              &#8220;Política de cookies&#8221; en su pantalla. Esto mostrará el aviso de consentimiento nuevamente, lo que le
              permitirá cambiar sus preferencias o retirar su consentimiento por completo.
            </p>
            <p>
              Además de esto, diferentes navegadores proporcionan diferentes métodos para bloquear y eliminar las cookies utilizadas
              por los sitios web. Puede cambiar la configuración de su navegador para bloquear / eliminar las cookies.
            </p>
            <p>
              Si desea más información más sobre los tipos de cookies de seguimiento y análisis de datos de Google&nbsp;
              <a href="https://www.google.com/intl/es_es/policies/technologies/types/" target="_blank" rel="noreferrer">haga clic aquí</a>.
            </p>
            <p>
              Para informarse sobre cómo eliminar las cookies de su explorador:
            </p>
            <ul>
              <li><a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank" rel="noreferrer">Firefox</a></li>
              <li><a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noreferrer">Chrome</a></li>
              <li><a href="https://support.microsoft.com/es-es/topic/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noreferrer">Internet Explorer</a></li>
              <li><a href="https://support.apple.com/kb/PH19214?viewlocale=es_ES&amp;locale=es_ES" target="_blank" rel="noreferrer">Safari</a></li>
              <li><a href="https://www.allaboutcookies.org/es/administrar-las-cookies/opera.html" target="_blank" rel="noreferrer">Opera</a></li>
            </ul>
            <p>
              Para obtener más información sobre cómo administrar y eliminar cookies, visite <a href="http://www.allaboutcookies.org" target="_blank" rel="noreferrer">www.allaboutcookies.org</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolicyComponent;
