import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  es: {
    translation: {
      'app.cookies.consent.text': 'Usamos cookies en nuestro sitio web para brindarle una mejor experiencia recordando sus preferencias.',
      'app.cookies.consent.button': 'Aceptar',
      'home.menu': 'Inicio',
      'about-us.menu': '¿Quiénes somos?',
      'about-us.title': '¿Quiénes somos?',
      'about-us.subtitle': 'Venecia IT, es una compañía de consultoría especializada en servicios de diseño de soluciones tecnológicas y Headhunter IT, donde trabajamos bajo una cultura enfocada a la transformación digital.',
      'about-us.subtitle2': 'Está formada por un equipo de profesionales con grado superior de ingeniería informática y telecomunicaciones, master en gestión de las TIC\'s y en gestión de proyectos de tecnología, con más de 16 años de trayectoria por cuenta ajena en el sector IT, que ha decidido emprender su propio camino, e inicia con la idea de automatizar la gestión de talento humano y empieza a desarrollar su primer producto propio denominado Venecia Talent Provider (VTP).',
      'about-us.subtitle3': 'Venecia, con la finalidad de ofrecer un servicio de calidad, analiza detenidamente los requerimientos de cada solicitud, poniendo en práctica los conocimientos en el sector tecnológico, aplicamos un plan estratégico, innovador y amigable adaptado a tus necesidades.',
      'about-us.button': 'Hablemos',
      'vacancies.title': 'En Venecia nos dedicamos a ti al 100% y nos esmeramos por ser los mejores.',
      'vacancies.subtitle': '¿Te animas a formar parte de nuestro equipo?',
      'vacancies.button.sing-up': 'Regístrate',
      'vacancies.button.list': 'Ofertas de Empleo',
      'vacancies.working': 'Lo sentimos, actualmente estamos trabajando para terminar esta sección.',
      'ecosystems.title': 'Ecosistemas IT',
      'ecosystems.menu': 'Ecosistemas IT',
      'ecosystems.card.management.title': 'Gestión',
      'ecosystems.card.development.title': 'Desarrollo',
      'ecosystems.card.technologies.title': 'Tecnologías',
      'ecosystems.card.ddbb.title': 'Databases',
      'ecosystems.card.data.title': 'Data & Analytics',
      'ecosystems.card.erp.title': 'ERP',
      'ecosystems.card.security.title': 'Seguridad',
      'ecosystems.card.system.title': 'Sistemas',
      'ecosystems.card.sales.title': 'Ventas',
      'services.title': 'Servicios y Soluciones',
      'services.menu': 'Servicios y Soluciones',
      'services.ithh.1': 'Los clientes (Contratadores), ahorrarán tiempo significativamente sin necesidad de interactuar con una gran multitud de portales de empleo y redes sociales y además únicamente pagarás un fee si se decide contratar a uno de nuestros consultores y también brindamos 60 días de garantía de reemplazo.',
      'services.ithh.2': 'Disminuirán de tu buzón significativamente la masiva cantidad de correos y tiempo invertido en entrevistas no frutíferas de tu día a día.',
      'services.ithh.3': 'Gracias a nuestra plataforma B2B, Ahorrarás sustancialmente tiempo y coste en la búsqueda de talentos con VTP.',
      'services.ithh.4': 'Venecia garantiza un 95% de incorporaciones de las vacantes gestionadas por VTP.',
      'services.swf.1': 'En nuestro servicio de software a medida, vamos de la mano con nuestros clientes para definir y crear las soluciones y productos digitales, que mejor se ajusten a sus necesidades de negocio.',
      'services.swf.2': 'Análisis & Diseño: Te ayudamos a identificar las áreas de mejora del negocio, para luego precisar la mejor solución.',
      'services.swf.3': 'Desarrollo: nuestros expertos, estarán siempre a tu disposición para implantar y desplegar tus aplicaciones y servicios.',
      'services.swf.4': 'Mantenimiento: En producción brindaremos el soporte que necesites.',
      'services.bpo.1': 'Venecia evalúa, y se asegura que sus consultores internos y externos, cuenten con las habilidades y herramientas, tanto técnicas como interpersonales necesarias para agregar valor al proyecto al que se incorporen.',
      'services.bpo.2': 'Ofrecemos servicios de BPO in-house, BPO off-site y Co-sourcing.',
      'sectors.menu': 'Sectores',
      'sectors.title': 'Sectores',
      'sectors.sector.1': 'Turismo y Ocio',
      'sectors.sector.2': 'Retail',
      'sectors.sector.3': 'Telecomunicaciones',
      'sectors.sector.4': 'Transporte y Logística',
      'sectors.sector.5': 'Salud',
      'sectors.sector.6': 'Banca y Seguros',
      'sectors.sector.7': 'Industrial',
      'sectors.sector.8': 'Gobierno y Servicios Públicos',
      'contact.title': 'Contáctanos',
      'contact.menu': 'Contáctanos',
      'contact.subtitle': '¡Captamos al mejor talento!',
      'contact.paragraph': '¡Cobertura internacional!',
      'contact.form.address': 'Dirección',
      'contact.form.phone': 'Teléfono',
      'contact.form.email': 'Correo Electrónico',
      'contact.form.send': 'Enviar',
      'contact.form.reset': 'Limpiar',
      'contact.form.name': ' Tu nombre',
      'contact.form.message': 'Mensaje',
      'contact.form.client': 'Necesito Profesional IT',
      'contact.form.candidate': 'Busco Trabajo',
      'contact.form.error': 'Disculpe, hubo un error al enviar el correo electrónico.',
      'contact.form.thanks': 'Gracias por ponerte en contacto',
      'contact.form.compliance': 'He leído y acepto la ',
      'footer.legal.warning': 'Aviso Legal',
      'footer.cookies.policy': 'Política de cookies',
      'footer.privacy.policy': 'Política de privacidad',
      'footer.rights': 'Todos los derechos reservados',
    },
  },
  en: {
    translation: {
      'app.cookies.consent.text': 'We use cookies on our website to give you a better experience by remembering your preferences.',
      'app.cookies.consent.button': 'Accept',
      'home.menu': 'Home',
      'about-us.menu': 'About us',
      'about-us.title': 'We are Venecia!',
      'about-us.subtitle': 'Venecia IT, is a consulting company specialized in design services for technological solutions and Headhunter IT, where we work under a culture focused on digital transformation.',
      'about-us.subtitle2': 'Formed by a team of professionals  with a higher degree in computer engineering and telecommunications, a master\'s degree in ICT management and technology project management, with more than 16 years of experience working for others in the IT sector, which has decided embark on his own path, and starts with the idea of automating the management of human talent and begins to develop his first own product called Venecia Talent Provider (VTP).',
      'about-us.subtitle3': 'Venecia, in order to offer a quality service, carefully analyzes the requirements of each request, putting into practice the knowledge in the technology sector, we apply a strategic, innovative and friendly plan adapted to your needs.',
      'about-us.button': 'Let\'s Talk',
      'vacancies.title': 'In Venecia we dedicate ourselves 100% to you and we strive to be the best.',
      'vacancies.subtitle': 'Do you dare to be part of our team?',
      'vacancies.button.sing-up': 'Sign Up',
      'vacancies.button.list': 'Job Offers',
      'vacancies.working': 'Sorry, we are currently working on finishing this section.',
      'ecosystems.title': 'IT Ecosystems',
      'ecosystems.menu': 'IT Ecosystems',
      'ecosystems.card.management.title': 'Management',
      'ecosystems.card.development.title': 'Development',
      'ecosystems.card.technologies.title': 'Technologies',
      'ecosystems.card.ddbb.title': 'Databases',
      'ecosystems.card.data.title': 'Data & Analytics',
      'ecosystems.card.erp.title': 'ERP',
      'ecosystems.card.security.title': 'Security',
      'ecosystems.card.system.title': 'Systems',
      'ecosystems.card.sales.title': 'Sales',
      'services.title': 'Services and Solutions',
      'services.menu': 'Services and Solutions',
      'services.ithh.1': 'Clients (Employers) will significantly save time without having to interact with a large number of job portals and social networks and you will also only pay a fee if you decide to hire one of our consultants and we also provide a 60-day replacement guarantee.',
      'services.ithh.2': 'They will significantly reduce the massive amount of emails and time invested in unsuccessful interviews from your day to day.',
      'services.ithh.3': 'Thanks to our B2B platform, you will substantially save time and cost in the talents searching with VTP.',
      'services.ithh.4': 'Venecia guarantees 95% incorporation of the vacancies managed by VTP.',
      'services.swf.1': 'In our custom software service, we go hand in hand with our clients to define and create the solutions and digital products that best suit their business needs.',
      'services.swf.2': 'Analysis and Design: We help you identify areas for business improvement, to then specify the best solution.',
      'services.swf.3': 'Development: our experts will always be at your disposal to implement and deploy your applications and services.',
      'services.swf.4': 'Maintenance: In production we will provide the support you need.',
      'services.bpo.1': 'Venecia evaluates and ensures that its internal and external consultants have the skills and tools, both technical and interpersonal, necessary to add value to the project they join.',
      'services.bpo.2': 'We offer in-house BPO, off-site BPO and Co-sourcing services.',
      'sectors.menu': 'Sectors',
      'sectors.title': 'Sectors',
      'sectors.sector.1': 'Tourism and Leisure',
      'sectors.sector.2': 'Retail',
      'sectors.sector.3': 'Telecommunications',
      'sectors.sector.4': 'Transport and Logistics',
      'sectors.sector.5': 'Health',
      'sectors.sector.6': 'Banking and Insurance',
      'sectors.sector.7': 'Industrial',
      'sectors.sector.8': 'Government and Public Services',
      'contact.title': 'Contact us',
      'contact.menu': 'Contact us',
      'contact.subtitle': 'We recruit the best talent!',
      'contact.paragraph': 'International coverage!',
      'contact.form.address': 'Direction',
      'contact.form.email': 'Email',
      'contact.form.phone': 'Phone',
      'contact.form.send': 'Send',
      'contact.form.reset': 'Clear',
      'contact.form.name': ' Your name',
      'contact.form.message': 'Message',
      'contact.form.client': 'I need an IT professional',
      'contact.form.candidate': 'I am looking for work',
      'contact.form.error': 'Sorry, there was an error sending the email.',
      'contact.form.thanks': 'Thank you for getting in touch!',
      'contact.form.compliance': 'I have read and accept the ',
      'footer.legal.warning': 'Legal Warning',
      'footer.cookies.policy': 'Cookies policy',
      'footer.privacy.policy': 'Privacy policy',
      'footer.rights': 'All rights reserved',
    },
  },
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    resources,
    lng: 'es', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
