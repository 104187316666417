import React from 'react';

import './section-title.component.css';

const SectionTitle = ({ title }) => (
  <div className="section-title">
    <h4 className="title">{title}</h4>
    <hr className="separator" />
  </div>
);

export default SectionTitle;
