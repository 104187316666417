import React from 'react';

import './card.component.css';

const Card = ({ image, title, description }) => (
  <div className="card" style={{ width: '18rem' }}>
    <img className="card-img-top" src={image} />
    <div className="card-body">
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  </div>
);

export default Card;
