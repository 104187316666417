import React from 'react';
import ReactDOM from 'react-dom';

import AppComponent from './app/app.component';
import './i18n';

import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';

/* eslint-disable */
(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "nswmf0249g");
/* eslint-enable */

ReactDOM.render(<AppComponent />, document.getElementById('root'));
