import React from 'react';
import Button from 'react-bootstrap/Button';

import './image-button.component.css';

const ImageButton = ({ backgroundImage, title, height, href, onClick }) => (
  <div
    className="image-button d-flex align-items-center justify-content-center"
    style={
      {
        backgroundImage: `url(${backgroundImage})`,
        height,
      }
    }
  >
    <Button variant="primary" href={href} onClick={onClick}>{title}</Button>
  </div>
);

export default ImageButton;
