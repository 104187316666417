import React from 'react';
import Button from 'react-bootstrap/Button';

import './video-button.component.css';

const VideoButton = ({ video, type, title, href }) => (
  <div
    className="video-button"
  >
    <video autoPlay muted loop id="videoTag">
      <source src={video} type={type} />
    </video>
    <div className="button-container d-flex align-items-end justify-content-left">
      <Button variant="primary" href={href}>{title}</Button>
    </div>
  </div>
);

export default VideoButton;
